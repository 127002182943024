<template>
  <div class="p-0 overflow-hidden">
    <app-scroll>
      <b-table
        v-if="contacts && contacts.length > 0"
        class="position-relative"
        :items="contacts"
        :responsive="true"
        :small="isSmall"
        :fields="fields"
        :busy="busy"
        primary-key="id"
        sort-icon-left
        show-empty
        empty-text="No matching records found"
        :table-class="contacts.length < 5 ? 'mb-5' : ''"
      >
        <!-- Column: User -->
        <template #cell(name)="{ item }">
          <b-media class="d-flex align-items-center">
            <p class="text-dark font-weight-extrabold mb-0 ml-1">
              {{ item.name }}
            </p>
          </b-media>
        </template>
        <template #cell(surname)="{ item }">
          <b-media class="d-flex align-items-center">
            <p v-if="item.surname" class="text-dark font-weight-extrabold mb-0 ml-1">
              {{ item.surname }}
            </p>
            <p v-else class="text-muted font-weight-extrabold mb-0 ml-1"> -- </p>
          </b-media>
        </template>
        <template #cell(job)="{ item }">
          <b-media class="d-flex align-items-center">
            <p v-if="item.jobTitle" class="text-dark mb-0 ml-1">
              {{ item.jobTitle }}
            </p>
            <p v-else class="text-muted font-weight-extrabold mb-0 ml-1"> -- </p>
          </b-media>
        </template>

        <template #cell(organizations)="data">
          <div>
            <div v-if="data.item.organizations && data.item.organizations.length > 0">
              <div v-for="(organization, index) in data.item.organizations" class="mb-25" :key="index">
                <p v-if="index < 3" class="mb-50 ml-75">
                  <b-link :to="organizationLocation(organization)">
                    {{ organization.name }}
                  </b-link>
                </p>
                <small v-if="index === 4" class="text-secondary font-weight-bold cursor-pointer ml-50" @click="showAllOrganizations(data.item)">{{ $t("action.show-more") }} </small>
              </div>
            </div>
            <p v-else class="text-muted font-weight-extrabold mb-0 ml-1"> -- </p>
          </div>
        </template>
        <template #cell(email)="{ item }" >
          <div v-for="(email, index) in item.emails" :key="index" class="ml-1">
            <div class="mb-0">
              <p class="mb-0">
                <small v-if="email.label" class="text-muted mb-0 mr-25">{{ email.label }}</small>
                <small v-else class="text-muted mb-0 mr-25">{{ $t("backoffice.settings.email-label") }}</small>
              </p>
              <a :class="item.emails.length > 1 ?'mb-25':''" target="_blank" :href="`mailto:${email.email}`">{{ email.email }}</a></div>
          </div>
          <p v-if="item.emails.length === 0" class="text-muted font-weight-extrabold mb-0 ml-1"> -- </p>
        </template>
        <template #cell(telfs)="{ item }" >
          <div v-for="(phone, index) in item.phones" :key="index" class="ml-1">
            <div :class="item.phones.length > 1?'mb-25':''">
              <p class="mb-0">
                <small v-if="phone.label" class="text-muted mb-0 mr-25">{{ phone.label }}</small>
                <small v-else class="text-muted mb-0 mr-25">{{ $t("backoffice.settings.phone-label") }}</small>
              </p>
              <a v-if="phone.country" :href="`tel:+${phone.country.phoneCode}${getPhone(phone)}`">+{{ phone.country.phoneCode }} {{ getPhone(phone) }}</a>
              <a v-if="!phone.country" :href="`tel:+${getPhone(phone)}`">{{ getPhone(phone) }}</a>
            </div>
          </div>
          <p v-if="item.phones.length === 0" class="text-muted font-weight-bold mb-0 ml-1"> -- </p>
        </template>
        <template #cell(links)="{ item }">
          <div class="ml-1">
            <span v-for="(link, index) in item.links" :key="index">
              <a :href="toAbsoluteURL(link.url)" target="_blank" class="mr-25">
                <feather-icon
                  :icon="link.label === 'Website' || link.label === 'Soundcloud' ? 'GlobeIcon' : `${link.label}Icon`"
                  size="18"
                  class="social-icons"
                />
              </a>
            </span>
          </div>
          <p v-if="item.links.length === 0" class="text-muted font-weight-extrabold mb-0 ml-1"> -- </p>
        </template>
        <template #cell(locations)="{ item }">
          <div v-if="item.locations" class="ml-1">
            <span v-for="location in item.locations" :key="location.key">
              <p class="mb-0">
                <small v-if="location.label" class="text-muted mb-0">{{ location.label }}</small>
                <small v-else class="text-muted mb-0">{{ $t("backoffice.settings.location-label") }}</small>
              </p>
              {{ location.address }}
            </span>
          </div>
          <p v-if="item.locations.length === 0" class="text-muted mb-0 ml-1 mt-25"> -- </p>
        </template>
        <!-- <template #cell(creator)="{ item }">
          <b-media class="d-flex align-items-center">
            <b-link :to="getCreatorLocation(item)" class="d-flex ">
              <p class="mb-0 ml-1">
                {{ item.ownedByMember.name }} {{ item.ownedByMember.surname }}
              </p>
            </b-link>
          </b-media>
        </template>
        <template #cell(date)="{ item }">
          <b-media class="d-flex align-items-center">
            <p class="text-dark mb-0 ml-1">
              {{ new Date(item.createdAt).toLocaleDateString() }}
            </p>
          </b-media>
        </template> -->
        <template #cell(actions)="{ item }">
          <div class=" d-flex">
            <b-dropdown
              v-if="canEdit"
              id="dropdown-dropleft"
              variant="link"
              right
              class="text-primary mt-n50 ml-n75"
              no-caret
            >
              <template #button-content class="p-0">
                <feather-icon icon="Edit2Icon" size="16" />
              </template>
              <b-dropdown-text class="bg-light">
                <p class="user-name font-weight-bolder mb-0">
                  {{ $t("edit-dropdown.title") }}
                </p>
              </b-dropdown-text>
              <b-dropdown-item @click="editItem(item)">
                {{ $t("edit-dropdown.details") }}
              </b-dropdown-item>
              <b-dropdown-item @click="editClassifiers(item)">
                {{ $t("members.edit.classifiers.name") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canEdit"
                @click="editCustom(item)"
              >
                {{ $t("members.edit.custom-fields.label") }}
              </b-dropdown-item>
            </b-dropdown>
            <div v-if="canDelete" class="ml-n1">
              <feather-icon
                icon="Trash2Icon"
                size="16"
                class="ml-50 text-primary pointer"
                @click="removeItem(item)"
              />
            </div>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center p-5">
            <b-spinner
              size="2rem"
              type="grow"
              class="d-block m-auto m-1"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
      </b-table>
      <div v-else class="horizontal-placeholder">
        <div>
          <img :src="membersPlaceholder" class="d-block" />
        </div>
        <div>
          <p class="text-primary text-center">
            {{ $t("available.message", { itemName: $t("contacts.title") }) }}
          </p>
        </div>
      </div>
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="contact"
        :member="currentMember"
        @remove-item="handleRemoveItem"
      />
      <classifiers-modal
        v-if="isClassifiers"
        v-model="isClassifiersModalVisible"
        :item-key="currentMember.key"
        morph-type="contacts"
        model-type="contact"
        :classifiers="currentMember.classifiers"
        @profile-updated="$emit('profile-updated')"
      />
      <!-- sortedCustomFields -->
      <customfields-modal
        v-if="isCFields"
        v-model="isCustomFieldsModalVisible"
        :item="currentMember"
        morph-type="contact"
        @profile-updated="$emit('profile-updated')"
      />
    </app-scroll>

    <!-- More organizations -->
    <b-modal
      v-model="isOrganizationsModalVisible"
      :title="$t('members.member-organizations')"
      :ok-title="$t('form.actions.save')"
      hide-footer
    >
      <p class="font-weight-bolder mt-1 text-center">
        {{ currentMember.name }} {{ currentMember.surname }} <span v-if="currentMember.email">({{ currentMember.email }})</span>
      </p>
      <div
        v-for="(organization, index) in organizationsMember"
        :key="index"
        class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-25"
      >
        <p class="mb-50">
          <b-link :to="organizationLocation(organization)">
            {{ organization.name }}
          </b-link>
        </p>
        <hr></hr>
      </div>
      </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive"
import { getImageResource } from "@/@core/utils/image-utils";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import PeoplePlaceholder from "@/assets/images/placeholders/light/people.svg";
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';
import { toAbsoluteURL } from '@core/utils/url-utils';
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { checkPermissions } from "@/@core/utils/roles-utils";
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import CustomfieldsModal from "@/@core/components/modal/CustomfieldsModal.vue";

export default {
  name: "ContactsTable",
  components: {
    AppScroll,
    UnshareModal,
    ClassifiersModal,
    CustomfieldsModal,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
  },
  data() {
    return {
      isSmall: false,
      selectedMember: null,
      lastLoadedPage: 1,
      contacts: [],
      customNames: [],
      currentMember: {},
      isUnshareModalVisible: false,
      isOrganizationsModalVisible: false,
      organizationsMember: [],
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isClassifiers: false,
      isCFields: false,

    };
  },
  computed: {
    classifiers() {
      if (this.$store.getters.classifiers.community) {
        return this.$store.getters.classifiers.community.unpaginated;
      }
      return [];
    },
    canDelete() {
      return checkPermissions(
        "delete",
        "contact",
        this.loggedMemberRoles,
        this.$store.getters.currentCollective
      );
    },
    canEdit() {
      return checkPermissions(
        "update",
        "contact",
        this.loggedMemberRoles,
        this.$store.getters.currentCollective
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$t("contacts.name"),
          sortable: this.isDetailView,
          thClass: 'pl-2',
        },
        {
          key: "surname",
          label: this.$t("contacts.surname"),
          sortable: this.isDetailView,
          thClass: 'pl-2',
        },
        {
          key: "job",
          label: this.$t("members.edit.job-title.label"),
          sortable: this.isDetailView,
          thClass: 'pl-2',
        },
        {
          key: "organizations",
          label: this.$t('members.edit.organizations.label'),
          sortable: this.isDetailView,
          thClass: 'pl-2',
        },
        {
          key: "email",
          label: this.$t("organizations.information.emails"),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: "links",
          label: this.$t("backoffice.settings.subtitle-links"),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: "telfs",
          label: this.$t("backoffice.settings.subtitle-phones"),
          sortable: false,
          thClass: 'pl-2',
        },
        {
          key: "locations",
          label: this.$t("backoffice.settings.subtitle-locations"),
          sortable: false,
          thClass: 'pl-2',
        },
        // Ocultamos esto porque tampoco es muy necesario, ya lo pondremos en otro sitio
        // {
        //   key: "creator",
        //   label: this.$t("contacts.creator"),
        //   sortable: false,
        //   thClass: 'pl-2',
        // },
        // {
        //   key: "date",
        //   label: this.$t("contacts.creation-date"),
        //   sortable: false,
        //   thClass: 'pl-2',
        // },
      ];
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    peoplePlaceholder() {
      return PeoplePlaceholder;
    },
    isDetailView() {
      return this.$route.name === 'contacts' || this.$route.name === 'societies-details';
    },
  },
  watch: {
    isClassifiersModalVisible() {
      if (this.isClassifiersModalVisible === false) {
        this.currentMember = {};
        this.isClassifiers = false;
      }
    },
    isCustomFieldsModalVisible() {
      if (this.isCustomFieldsModalVisible === false) {
        this.currentMember = {};
        this.isCFields = false;
      }
    },
    classifiers(value) {
      if (value.length > 0 && this.isDetailView) {
        this.createClassifiersColumns()
        this.createFieldsColumns()
      }
    },
    fields(value) {
      return value
    },
  },
  async created() {
    this.contacts = this.members.map(item=>item);
    this.fetchClassifiers();
    if (this.isDetailView) {
      this.createClassifiersColumns()
      this.createFieldsColumns()
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'classifiers',
        storedKey: 'community',
        page: 1,
        requestConfig: { morphType: 'contact', count: 1000 },
      });
    },
    createClassifiersColumns() {
      if (this.classifiers?.length > 0) {
        let typeNames = this.classifiers.map((item) => item.typeName);
        typeNames = [...new Set(typeNames)];

        this.dataClassifiers();
        return typeNames.map((item, index) => {
          this.fields.splice(8, 0, {
            key: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            label: item[this.locale] ? item[this.locale] : Object.values(item)[0],
            sortable: false,
            thClass: 'pl-50',
            tdAttr: (value, key, it) => {
              let style = '';
              it.classifiers.map(x => {
                if ((x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0]) == (item[this.locale] ? item[this.locale] : Object.values(item)[0])) {
                  style = { style: `color: ${x.backgroundColor !== '#ffffff' ? x.backgroundColor : x.fontColor }; font-weight: 600` }
                }
              });
              return style;
            },
            formatter: (value) => {
              const allItems = [];
              if (value && Array.isArray(value)) {
                value?.map((item) => {
                  if (typeof item === 'object') {
                    allItems.push(item[this.locale] ? item[this.locale] : Object.values(item)[0]);
                  } else {
                    allItems.push(item);
                  }
                });
                return allItems.join(', ');
              }
              return value;
            },
          });
          if (this.canDelete || this.canEdit) {
          this.pushActions();
        }
        });
      }
      if (this.canDelete || this.canEdit) {
        this.pushActions();
      }
    },
    createFieldsColumns() {
      this.contacts.map((item) => {
        item.custom?.map((i) => {
          const field = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.customNames.push(field);
        });
      });
      this.dataFields();
      this.customNames = [...new Set(this.customNames)];
      this.customNames.map((item, index) => {
        this.fields.splice(8, 0, {
          key: item,
          label: item,
          sortable: false,
          thClass: 'pl-50',
          formatter: (value) => {
            if (typeof value === 'boolean') {
              if (value === true) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.true',
                );
              }
              if (value === false) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.false',
                );
              }
            }
            return value;
          },
        });
      });
    },
    dataFields() {
      this.members.map((n, index) => {
        n?.custom?.map((i) => {
          const typeName = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.contacts[index][typeName] = i.value;
        });
      });
    },
    dataClassifiers() {
      this.contacts = this.members.map((item) => item);
      this.members.map((n, index) => {
        n.classifiers.map((i) => {
          this.classifiers.map((x, p) => {
            if (i.key === x.key) {
              const typeName = x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0];
              if (Array.isArray(this.contacts[index][typeName])) {
                if (this.contacts[index][typeName].includes(i.name)) {
                  return;
                }
                this.contacts[index][typeName].push(i.name);
              } else {
                this.contacts[index][typeName] = [];
                this.contacts[index][typeName].push(i.name);
              }
            }
          });
        });
      });
    },
    organizationLocation(organization) {
      if (organization.slug) {
        return {
          name: "organization",
          params: {
            slug: organization.slug,
            communityId: this.$route.params.communityId,
          },
        };
      }
      return {
        name: "societies-details",
        params: {
          organizationId: organization.key,
          communityId: this.$route.params.communityId,
        },
      };
    },
    pushActions() {
      this.fields.push({
        key: 'actions',
        label: this.$tc("backoffice.classifiers.actions"),
        sortable: false,
        thClass: 'pl-50',
      });
    },
    editClassifiers(item) {
      this.isClassifiers = true;
      this.currentMember = item;
      this.isClassifiersModalVisible = true;
    },
    editCustom(item) {
      this.isCFields = true;
      this.currentMember = item;
      this.isCustomFieldsModalVisible = true;
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    removeItem(item) {
      this.currentMember = item;
      this.isUnshareModalVisible = true;
    },
    editItem(item) {
      this.$emit('edit', item);
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: '/deleteContact',
            forceUpdate: true,
            customName: 'contacts',
            requestConfig: {
              key: this.currentMember.key,
            },
          });
          this.notifySuccess(
            this.$t("contacts.messages.delete.success")
          );
          this.contacts = this.members.map(item=>item);
        } catch (error) {
          this.notifyError(
            this.$t("contacts.messages.delete.error")
          );
        }
        this.currentMember = {};
      }
    },
    getImageResource,
    toAbsoluteURL,
    showAllOrganizations(item) {
      this.organizationsMember = item.organizations;
      this.currentMember = item;
      this.isOrganizationsModalVisible = true;
    },
    getCreatorLocation(item) {
      return {
        name: 'member-details',
        params: {
          username: item.ownedByMember.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    getPhone(phone) {
      return phone.phone ?? phone.number;
    }
  },
};
</script>
<style lang="scss" scoped>
.speaker-avatar__img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 13rem;
  border-radius: 1.2rem;
}
.speaker-card {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.speaker-avatar {
  margin: auto;
  display: block;
}
.disabled-button:hover {
  cursor: auto;
}
.disabled-icon {
  color: rgb(212, 212, 212);
}

</style>
